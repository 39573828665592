<template>
  <div class="auth-wrapper">
    <!-- <Header /> -->
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/auth/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  components: { Header, Footer },
  name: "auth",
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100vh;
}
</style>